import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Email Notification",
  "path": "/Web_User_Interface/720p_Series/Alarm/Email/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. If you want to send the email to more than one address, simply separate the addresses with a semicolon.",
  "image": "./WebUI_720p_SearchThumb_Alarm_Email.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_Email.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Alarm Menu // Alarm Email Notification' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. If you want to send the email to more than one address, simply separate the addresses with a semicolon.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_Email.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_Email.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/Email/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/Email/' crumbLabel="Email Notification" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "alarm-email-notification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-email-notification",
        "aria-label": "alarm email notification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Email Notification`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. If you want to send the email to more than one address, simply separate the addresses with a semicolon.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/44f98fbdc4b453e947d855085a8e3716/6bbf7/WebUI-Alarm_Email.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADBUlEQVQ4y5VU6U4aURjlqZq+R2v7GD6GTfxjbQIGhRRZFBMhoe4CBjAILqyyjFFgWFU2t2hcT3M+AzhqUktycoc735x7zrdcncfzB1++DuHT588Y+vYdU1NTMJvNsNlsMJlMMBqNsmexWGA2m2Q1GAzQ6w0SNz09DavVKnGEbmFhAXq9HiMjP+TDvb09JBIJWePxODKZDLLZLHK5XH/lXjqdRqFQgMfjwfDwMDY2NgS6mRkH7HY7XC4XfD4/isUi8nkFqVRKCDrdLs7OztDpdNDtdjXg/unpqbjZ2dkRATpKHhv7CavNhtXVVSEkUSwWQyqZEhW1Wg2NRuNdHB8fY2VlBdFoVEh1VEfvbrcb6+vrKJVKODo6EoX5fB7lclmUXF5e4vz8/A2azSaYtu3tbezu7kLncNhhMpvFMhWqaklIDw8PRV2lWkWlUkG1WkOr1RLrL0HLi4uLA0KL5TcmJiZgnJyEy+0WRaViCQeKgkKhiHq9LoRce4TtdrsPKtQQzs/PY25uDltbEQSDQZTUEhRFQSqZhKqWcXFxIZZ7hXlJRpycnGgJq5WKWORJzVZLCsDcsS1YHOZTUQ5kpb33oCFsNZto1BvA0xOur6+f7dVq/bzd3Nz0cX9//wZUqSlKNBKFw+6QKrOyVPj4+IiP/pgGDaF33Ytf4+MYHR2Fz+eTvmIQ24S4urrqr3TwGm/a5qzbRafdldNogVbX1takjZxOJ2Znnf2W4ses9Gtocri0tCR2OZOscrVaRTaTQSweF8RjcZnrXD6Pi1dNzQ7gCGoUcjr8/g1RxWf2IavLCyKZTAoSiSQ2N0MyXs//EwKOJ6tPUXwnhPvpfWQzWblBqISWVVWVQE5KD3yvSLMP9hhDRxrL4XBYTuCARyIRycnDwwPu7u404B5z/Hr/9vYWy8vLA4WhUEis0jIJA4GAHMCgf4EiqI4XDN3JbUOpJGPL8AQGcRxZqI+AHeD3+weEW+EwvF6v3LaUzWSzwf8HvFhJJpbJHAwGwFz2TqFqrh9BL5ZkVPgXS9akBaPEPBkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44f98fbdc4b453e947d855085a8e3716/e4706/WebUI-Alarm_Email.avif 230w", "/en/static/44f98fbdc4b453e947d855085a8e3716/d1af7/WebUI-Alarm_Email.avif 460w", "/en/static/44f98fbdc4b453e947d855085a8e3716/04ac8/WebUI-Alarm_Email.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/44f98fbdc4b453e947d855085a8e3716/a0b58/WebUI-Alarm_Email.webp 230w", "/en/static/44f98fbdc4b453e947d855085a8e3716/bc10c/WebUI-Alarm_Email.webp 460w", "/en/static/44f98fbdc4b453e947d855085a8e3716/d8378/WebUI-Alarm_Email.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44f98fbdc4b453e947d855085a8e3716/81c8e/WebUI-Alarm_Email.png 230w", "/en/static/44f98fbdc4b453e947d855085a8e3716/08a84/WebUI-Alarm_Email.png 460w", "/en/static/44f98fbdc4b453e947d855085a8e3716/6bbf7/WebUI-Alarm_Email.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/44f98fbdc4b453e947d855085a8e3716/6bbf7/WebUI-Alarm_Email.png",
              "alt": "Web User Interface - 720p Series - Alarm Email",
              "title": "Web User Interface - 720p Series - Alarm Email",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. If you want to send the email to more than one address, simply separate the addresses with a semicolon ;. E.g. `}<a parentName="p" {...{
        "href": "mailto:max.mustermann@instar.de"
      }}>{`max.mustermann@instar.de`}</a>{` ; `}<a parentName="p" {...{
        "href": "mailto:franz.fingerhut@instar.de."
      }}>{`franz.fingerhut@instar.de.`}</a>{` It is recommended to fill out the subject line and the email text body to avoid the mail to be caught by any spam guards in place.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      